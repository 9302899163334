import { lowerCase } from 'lodash'
import i18n from '@/libs/i18n'

const typesDuration = {
  day: { value: 'day', name: lowerCase(i18n.t('common.day')), coeff: 1 / 1440 },
  hour: { value: 'hour', name: lowerCase(i18n.t('common.hour')), coeff: 1 / 60 },
  min: { value: 'min', name: lowerCase(i18n.t('common.min')), coeff: 1 },
}

export default typesDuration
