<template>
  <app-input
    :id="id"
    :value="localValue"
    :append="append"
    type="number"
    :step="step"
    :min="0"
    v-bind="$attrs"
    v-on="{ ...omit($listeners, 'input') }"
    @input="convertToMainUnit($event)"
  >
    <template v-if="!forceTextMode && unitsType === 'minutes'" #append>
      <b-form-select
        :value="unit"
        :options="typesDuration"
        value-field="value"
        text-field="name"
        @change="$emit('update:unit', $event)"
      />
    </template>
  </app-input>
</template>

<script>
import { defineComponent, computed, watch, ref } from '@vue/composition-api'
import { omit } from 'lodash'

import typesDuration from '@/constants/typesDuration'

// TODO: Finish to abstract 'hour' reference when (and if) we'll convert others types (ex: km => m)

export default defineComponent({
  name: 'AppQuantityPerUnitInput',

  props: {
    value: {
      type: [Number, String],
      default: null,
    },
    id: {
      type: String,
      default: 'quantityPerUnitInput',
    },
    unit: {
      type: String,
      default: null,
    },
    unitsType: {
      type: String,
      default: null,
    },
    forceTextMode: {
      type: Boolean,
      default: false,
    },
    step: {
      type: String,
      default: undefined,
    },
  },

  setup(props, ctx) {
    const { $i18n, $getMeasurementUnitItn, _cloneDeep } = ctx.root
    const $emit = ctx.emit

    const oldUnit = ref(_cloneDeep(props.unit))
    const localValue = ref(_cloneDeep(props.value))

    const getAppendQuantity = {
      footprint: { abbr: 'm2' },
      mtow: { abbr: 'kg' },
      wingspan: { abbr: 'm' },
    }

    const append = computed(() => {
      if (getAppendQuantity[props.unitsType]) {
        return $getMeasurementUnitItn(getAppendQuantity[props.unitsType].abbr)
      }
      if (props.forceTextMode && props.unitsType === 'minutes') {
        return $i18n.t('common.hour')
      }
      return undefined
    })

    const conversionToMainUnit = {
      minutes: val => (val ? val / typesDuration[props.unit].coeff : val),
    }

    const conversionToSelectedUnit = {
      minutes: val => (val ? val * typesDuration[props.unit].coeff : val),
    }

    // ? Preparation for others types of units (km => m, etc)
    const convertToSelectedUnit = val => {
      localValue.value = conversionToSelectedUnit[props.unitsType]
        ? conversionToSelectedUnit[props.unitsType](val)
        : val

      const result = (props.value / typesDuration[oldUnit.value].coeff) * typesDuration[props.unit].coeff
      oldUnit.value = props.unit
      return result
    }

    const convertToMainUnit = val => {
      const convertedVal = conversionToMainUnit[props.unitsType]
        ? conversionToMainUnit[props.unitsType](val)
        : val

      $emit('input', convertedVal)
    }

    watch(() => props.value, val => {
      if (!props.forceTextMode && props.unit) convertToSelectedUnit(val)
    }, { immediate: true })

    watch(() => props.unit, (newVal, oldVal) => {
      // if `null` or `0`, no operation && no immediately watched (oldVal === undefined only on immediately watched)
      if (oldVal && localValue.value) {
        localValue.value = (localValue.value / typesDuration[oldVal].coeff) * typesDuration[newVal].coeff
      }
    }, { immediate: true })

    return {
      append,
      localValue,
      convertToMainUnit,
      typesDuration,
      omit,
    }
  },
})
</script>
