<template>
  <section>
    <div v-show="!openForm" class="app-calendar overflow-hidden border">
      <div class="row no-gutters">
        <!-- Calendar -->
        <div class="col position-relative">
          <div class="card shadow-none border-0 mb-0 rounded-0">
            <div class="card-body pb-0">
              <full-calendar ref="fullCalendar" :options="calendarOptions" class="full-calendar" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <keep-alive>
      <form-calendar
        v-if="openForm"
        :date-info="dateInfo"
        :event-info="[eventInfo]"
        v-on="$listeners"
        @display-calendar="displayCalendar()"
      />
    </keep-alive>

    <footer-form-service
      class="mt-2"
      next
      previous
      @click:previous="$emit('previous-tab')"
      @click:next="$emit('next-tab')"
    />
  </section>
</template>

<script>
import { defineComponent, inject, reactive, ref } from '@vue/composition-api'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import moment from 'moment'

import momentPlugin from '@fullcalendar/moment'
import FormCalendar from './FormCalendar.vue'
import FooterFormService from './FooterFormService.vue'

export default defineComponent({
  name: 'AvailabilityPrices',

  components: {
    FormCalendar,
    FullCalendar,
    FooterFormService,
  },

  setup(_props, ctx) {
    const { $router, $i18n, $options } = ctx.root
    const product = inject('product')

    const openForm = ref(!!ctx.root.$route.query.rid)
    const eventInfo = ref(null)
    const eventDate = ref(null)
    const dateInfo = ref(null)
    const displayedDates = ref({})

    const getDay = day => (day ? moment(day).format('YYYY-MM-DD') : null)

    const fullCalendar = ref(null)

    const calendarOptions = reactive({
      plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin, momentPlugin],
      initialView: ctx.root.$route.query.mode,
      initialDate: moment(ctx.root.$route.query.startAt).endOf('isoWeek').format('YYYY-MM-DD'),
      headerToolbar: {
        start: 'sidebarToggle, prev, next, title',
        center: 'addTarifsBtn',
        end: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth,today',
      },
      views: { dayGridMonth: { displayEventEnd: true } },
      customButtons: {
        addTarifsBtn: {
          text: $i18n.t('service.calendar.add_price'),
          click: () => {
            if (ctx.root.$route.name !== 'productShow') { openForm.value = true }
          },
        },
      },
      events: [],
      datesSet: event => {
        if (getDay(event.start) === displayedDates.value.startAt && displayedDates.value.mode === event.view.type) return

        displayedDates.value = {
          startAt: getDay(event.start),
          endAt: getDay(event.end),
          mode: event.view.type,
        }
        $router.replace({ query: { ...ctx.root.$route.query, ...displayedDates.value, endAt: undefined } })
        // eslint-disable-next-line no-use-before-define
        fetchPriceCalendar({ startAt: getDay(event.start), endAt: getDay(event.end) })
      },
      dateClick: date => {
        openForm.value = true
        eventInfo.value = null
        dateInfo.value = date
      },
      eventClick: calEvent => {
        eventInfo.value = calEvent.event
        openForm.value = true
        $router.replace({
          query: {
            ...ctx.root.$route.query,
            rid: calEvent.event._def.groupId,
          },
        })
      },
      textEscape: false,
      eventResizableFromStart: true,
      fixedWeekCount: false,
      weekNumberCalculation: 'ISO',
      dragScroll: true,
      navLinks: true,
      eventTimeFormat: { // like '14:30:00'
        hour: '2-digit',
        minute: '2-digit',
        hourCycle: 'h23',
        meridiem: false,
      },
      slotLabelFormat: { // like '14:30:00'
        hour: '2-digit',
        minute: '2-digit',
        hourCycle: 'h23',
        meridiem: false,
      },
      // dayHeaderFormat: 'dd, D MMM', // ! bug formatter (error console)
      locale: $i18n.locale,
    })

    const fetchPriceCalendar = datesRange => {
      product.value.fetchPriceCalendar(datesRange, ctx.root.$route.params.service_id, ctx.root.$route.query.vid).then(() => {
        calendarOptions.events = product.value.mainVariant.priceRanges.map(price => {
          const allDay = price.startHour === price.endHour
          const recur = price.daysOfWeek.length < 7
          const { priceFormat } = $options.filters
          const title = price.minPrice === price.maxPrice
            ? `${price.title}: ${priceFormat(price.minPrice)}`
            : `${price.title}: [${priceFormat(price.minPrice)} - ${priceFormat(price.maxPrice)}]`

          return {
            allDay,
            groupId: price.id,
            title,
            daysOfWeek: recur ? price.daysOfWeek : null,
            startTime: !allDay ? price.startHour.slice(0, 5) : null, // 08:00 format || null
            endTime: !allDay ? price.endHour.slice(0, 5) : null,
            color: '#DCC181',
            textColor: 'white',
            start: moment(price.startAt).format('YYYY-MM-DD'),
            end: price.endAt ? moment(price.endAt).add(1, 'days').format('YYYY-MM-DD') : null,
            startRecur: (!allDay || (allDay && !price.endAt))
              ? moment(price.startAt).format('YYYY-MM-DD') : null,
            endRecur: !allDay ? moment(price.endAt).add(1, 'days').format('YYYY-MM-DD') : null,
          }
        })
      })
    }

    const displayCalendar = () => {
      openForm.value = false
      eventInfo.value = false
      dateInfo.value = null
      fetchPriceCalendar({ startAt: displayedDates.value.startAt, endAt: displayedDates.value.endAt })
      if (ctx.root.$route.query.rid) {
        $router.replace({ query: { ...ctx.root.$route.query, rid: undefined } })
      }
    }

    return {
      product,
      fetchPriceCalendar,
      displayCalendar,
      displayedDates,
      fullCalendar,
      calendarOptions,
      openForm,
      dateInfo,
      eventInfo,
      eventDate,
    }
  },
})
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";

.fc-daygrid-body.fc-daygrid-body-unbalanced {
  width: 100% !important;
}

.fc-scrollgrid-sync-table {
  width: 100% !important;
}

.fc-col-header {
  width: 100% !important;
}

.fc-addTarifsBtn-button {
  border-color: #DCC181 !important;
  background-color: #DCC181 !important;
}
</style>
