<template>
  <b-row>
    <b-col sm="4">
      <app-input
        id="vat"
        v-model.number="product.mainVariant.vatRate"
        :label="$t('common.vat')"
        rules="positive|required"
        append="%"
        :disabled="$route.name === 'productShow'"
        min="0"
        step="0.01"
        type="number"
      />
    </b-col>

    <b-col sm="4">
      <!--Type PRICE-->
      <enum-select id="pricingType" v-model="product.mainVariant.pricingType" :disabled="$route.name === 'productShow'" :label="$t('service.prices.type')" enum-class="PricingType" required :clearable="false" />
    </b-col>

    <!--Type UNIT-->
    <b-col sm="4">
      <enum-select
        id="measurement"
        v-model="product.mainVariant.packing"
        :label="$t('service.prices.type_measurement')"
        enum-class="ProductPacking"
        :clearable="false"
        :disabled="$route.name === 'productShow'"
        required
        object-format
      />
    </b-col>
    <span v-if="product.mainVariant.calendarMode" class="ml-2 mb-3">
      <i>{{ $t('service.prices.common_fields') }}</i>
    </span>
  </b-row>
</template>

<script>
import { defineComponent, inject } from '@vue/composition-api'

export default defineComponent({
  name: 'PriceGlobalFields',

  setup() {
    const product = inject('product')

    return {
      product,
    }
  },
})
</script>
