<template>
  <section ref="formBracket" :style="{ height: trHeight }" class="repeater-form row-price">
    <!-- EACH PRICE -->
    <b-row v-for="(price, index) in serviceVariant.prices" :key="index" ref="rowSlicePrice" class="border-top rounded py-1 my-1">
      <b-col xl="12" md="12">
        <b-form-group :label="$t('service.prices.conditioning')" label-for="item-name">
          <b-form-input
            v-model.number="price.packingQuantity"
            :placeholder="$t('service.prices.conditioning')"
            :state="checkConditioning(price, index)"
            type="number"
          />
        </b-form-group>
      </b-col>
      <b-col xl="3" md="6">
        <b-form-group :label="$t('service.prices.excluding_taxes')" label-for="price-excluding-tax">
          <b-input-group :append="$store.state.appConfig.currency">
            <b-form-input v-model.number="price.sellingPriceExcludingTaxes" :raw="false" type="number" />
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col xl="3" md="6">
        <b-form-group :label="$t('common.vat')" label-for="price-vat">
          <b-input-group append="%">
            <b-form-input v-model.number="price.vatRate" :raw="false" type="number" />
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col xl="3" md="6">
        <b-form-group :label="$t('service.prices.including_taxes')" label-for="price-including-tax">
          <b-input-group :append="$store.state.appConfig.currency">
            <b-form-input v-model.number="price.sellingPriceIncludingTaxes" :raw="false" type="number" />
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col xl="3" md="6">
        <b-form-group :label="$t('service.prices.eco_tax')" label-for="price-eco-tax">
          <b-input-group :append="$store.state.appConfig.currency">
            <b-form-input v-model.number="price.ecologicalTax" :raw="false" type="number" />
          </b-input-group>
        </b-form-group>
      </b-col>

      <!-- ADD REMOVE BUTTON -->
      <b-col class="d-flex" cols="1">
        <b-button-group>
          <b-button v-if="serviceVariant.prices.length > 1" v-ripple.400 size="md" variant="outline-primary" @click="modalDeleteItem(index)">
            <font-awesome-icon v-b-tooltip.hover.bottom.v-danger icon="minus" class="text-danger" :title="$t('action.delete')" />
          </b-button>
          <b-button v-if="index === serviceVariant.prices.length - 1" v-ripple.400 size="md" variant="outline-primary" @click="repeatAgain">
            <font-awesome-icon v-b-tooltip.hover.bottom.v-danger icon="plus" class="text-success" :title="$t('action.add')" />
          </b-button>
        </b-button-group>
      </b-col>
      <hr />
    </b-row>
  </section>
</template>

<script>
import { heightTransition } from '@core/mixins/ui/transition'

export default {
  name: 'PriceLot',

  mixins: [heightTransition],
  props: {
    serviceVariant: {
      type: Object,
      default() {
        return {
          id: 1,
          description: 'Mr. Rocio Sawayn',
          seoMetaTitle: null,
          seoMetaDescription: null,
          stockQuantity: null,
          reference: 'Missouri Bradtke',
          internalReference: 'Mr. Deontae Schultz PhD',
          pcb: null,
          minimumOrderQuantity: null,
          stockAlert: null,
          rateOptionMandatory: false,
          rateOptionMultiple: false,
          recommendedRetailPrice: null,
          discountedPrice: false,
          discountedType: null,
          discountedPriceStartAt: null,
          discountedPriceEndAt: null,
          pricingType: 'price_per_slice',
          attributeValues: [],
          visibilityStartAt: '2021-08-25T00:00:00+00:00',
          visibilityEndAt: '1981-12-03T00:00:00+00:00',
          files: [],
          prices: [
            {
              id: 1,
              fromQuantity: 0,
              toQuantity: 47,
              sellingPriceExcludingTaxes: 3983.24,
              vatRate: 19.62,
              sellingPriceIncludingTaxes: 4764.75,
              ecologicalTax: null,
              packingQuantity: 1,
            },
            {
              id: 2,
              fromQuantity: 48,
              toQuantity: 143,
              sellingPriceExcludingTaxes: 5974.86,
              vatRate: 19.62,
              sellingPriceIncludingTaxes: 7147.13,
              ecologicalTax: null,
              packingQuantity: 1,
            },
            {
              id: 3,
              fromQuantity: 144,
              toQuantity: 239,
              sellingPriceExcludingTaxes: 8962.29,
              vatRate: 19.62,
              sellingPriceIncludingTaxes: 10720.69,
              ecologicalTax: null,
              packingQuantity: 1,
            },
            {
              id: 4,
              fromQuantity: 240,
              toQuantity: 326,
              sellingPriceExcludingTaxes: 13443.44,
              vatRate: 19.62,
              sellingPriceIncludingTaxes: 16081.04,
              ecologicalTax: null,
              packingQuantity: 1,
            },
            {
              id: 5,
              fromQuantity: 327,
              toQuantity: 389,
              sellingPriceExcludingTaxes: 20165.16,
              vatRate: 19.62,
              sellingPriceIncludingTaxes: 24121.56,
              ecologicalTax: null,
              packingQuantity: 1,
            },
          ],
          extendedData: [],
        }
      },
    },
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    checkConditioning(currentRow, index) {
      let valid = true
      if (index >= 1) {
        if (this.serviceVariant.prices[index - 1].packingQuantity > this.serviceVariant.prices[index].packingQuantity) {
          valid = false
        }
      }
      return valid
    },
    repeatAgain() {
      const newSlicePrice = this._cloneDeep(this.serviceVariant.prices[this.serviceVariant.prices.length - 1])
      newSlicePrice.packingQuantity += this.serviceVariant.prices[0].packingQuantity
      this.serviceVariant.prices.push(newSlicePrice)
      this.$nextTick(() => {
        this.trAddHeight(this.$refs.rowSlicePrice[0].offsetHeight)
      })
    },
    checkFromState(currentRow, index) {
      let valid = true
      if (currentRow.fromQuantity > currentRow.toQuantity) {
        valid = false
      }
      if (index >= 1) {
        if (this.serviceVariant.prices[index - 1].toQuantity > this.serviceVariant.prices[index].fromQuantity) {
          valid = false
        }
      }
      return valid
    },
    modalDeleteItem(index) {
      this.$bvModal
        .msgBoxConfirm(this.$t('alert.delete_confirmation.message'), {
          title: this.$t('alert.delete_confirmation.title'),
          size: 'sm',
          okVariant: 'primary',
          okTitle: this.$t('common.yes'),
          cancelTitle: this.$t('common.no'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.alert(this.$t('alert.delete.success'))
            this.serviceVariant.prices.splice(index, 1)
            this.trTrimHeight(this.$refs.rowSlicePrice[0].offsetHeight)
            this.initTrHeight()
          }
        })
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        if (this.$refs.form) {
          this.trSetHeight(this.$refs.form.scrollHeight)
        }
      })
    },
  },
}
</script>
