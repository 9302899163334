<template>
  <div>
    <!--BY UNIT-->
    <price-unity v-if="product.mainVariant.pricingType === 'unit_price' && value[0]" v-model="value" @input="$emit('input', $event)" />

    <!-- TODO when api will be ready -->
    <!-- <price-lot
      v-else-if="product.mainVariant.pricingType === 'price_per_lot'"
      :service-variant.sync="product.mainVariant"
    /> -->

    <price-slice v-else-if="product.mainVariant.pricingType === 'price_per_slice'" :value="value" :tab-id="tabId" @input="$emit('input', $event)" />
  </div>
</template>

<script>
import { defineComponent, inject } from '@vue/composition-api'

import PriceUnity from '@/views/product/component/priceType/PriceUnity.vue'
import PriceSlice from '@/views/product/component/priceType/PriceSlice.vue'
import PriceLot from '@/views/product/component/priceType/PriceLot.vue'

export default defineComponent({
  name: 'PriceTypes',

  components: {
    PriceUnity,
    PriceSlice,
    PriceLot,
  },

  props: {
    value: {
      type: Array,
      default: () => [],
      required: true,
    },
    tabId: {
      type: String,
      default: '',
    },
  },

  setup() {
    const product = inject('product')

    return {
      product,
    }
  },
})
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
.row-bracket,
.row-set {
  &-price:not(:last-child) {
    border-bottom: 1px solid #e0e0e0;
  }
}
</style>
