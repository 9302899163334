<template>
  <b-card class="mb-0 h-100">
    <div class="d-flex justify-content-between">
      <h2 class="d-inline">{{ $t('service.price_management') }}</h2>
      <app-checkbox-calendar
        id="calendar-mode"
        v-model="product.mainVariant.calendarMode"
        :label="$t('service.calendar.title')"
        @change="pricingCustom = false"
      />
    </div>

    <validation-observer
      v-if="product && !product.mainVariant.calendarMode"
      ref="priceManagementRef"
      tag="form"
      @submit.prevent="onSubmit"
    >
      <b-row :class="['d-flex align-items-center mt-2', {'mb-3 pb-3': !pricingCustom}]">
        <b-col cols="12">
          <b-form-checkbox
            v-model="pricingCustom"
            name="pricing-custom"
            :disabled="$route.name === 'productShow'"
            switch
            inline
            @change="() => {
              switchPrices()
              product.mainVariant.visible = true
            }"
          >
            {{ $t('service.prices.custom') }}
          </b-form-checkbox>
          <b-form-checkbox
            v-if="pricingCustom"
            v-model="product.mainVariant.visible"
            name="visible"
            :disabled="$route.name === 'productShow'"
            switch
            inline
          >
            {{ $t('service.prices.visible') }}
          </b-form-checkbox>
        </b-col>
        <span style="font-size: 12px;" class="ml-4 pl-1"><i>{{ $t('service.prices.custom_note') }}</i></span>
      </b-row>

      <price-global-fields v-if="pricingCustom" class="mt-2" />

      <b-tabs v-if="pricingCustom && isHangars" align="left" nav-class="mb-0">
        <b-tab
          v-for="period in periods"
          :id="period.tab"
          :key="period.tab"
          :active="period.tab === tabActive"
          class="tab"
          @click="currentPeriod = period.tab"
        >
          <template #title>
            <div class="title">
              <span>{{ period.label }}</span>
            </div>
          </template>

          <price-types
            :key="currentKey"
            :tab-id="currentPeriod"
            :value="product.mainVariant.prices.filter(price => price.periodicity === currentPeriod)"
            @input="event => {
              const othersPeriodsPrices = product.mainVariant.prices.filter(price => price.periodicity !== currentPeriod)
              product.mainVariant.prices = othersPeriodsPrices.concat(event)
            }"
          />
        </b-tab>
      </b-tabs>

      <!-- SELECTOR TYPE PRICE -->
      <price-types
        v-else
        v-show="pricingCustom"
        :key="currentKey"
        v-model="product.mainVariant.prices"
        :tab-id="currentPeriod"
      />
      <footer-form-service v-if="$route.name === 'productShow'" previous next @click:next="$emit('next-tab')" @click:previous="$emit('previous-tab')" />
      <footer-form-service v-else previous save save-next @click:previous="$emit('previous-tab')" @click:save-next="nextRedirection = true" />
    </validation-observer>

    <availability-prices v-else v-on="$listeners" />
  </b-card>
</template>

<script>
import { defineComponent, inject, ref } from '@vue/composition-api'

import AppCheckboxCalendar from '@/components/AppCheckboxCalendar.vue'
import AvailabilityPrices from '../component/AvailabilityPrices.vue'
import PriceTypes from '../component/PriceTypes.vue'
import FooterFormService from '../component/FooterFormService.vue'
import PriceGlobalFields from '../component/PriceGlobalFields.vue'

export default defineComponent({
  name: 'PriceManagement',

  components: {
    PriceTypes,
    AppCheckboxCalendar,
    FooterFormService,
    AvailabilityPrices,
    PriceGlobalFields,
  },

  setup(_props, ctx) {
    const { $i18n, toaster, _cloneDeep } = ctx.root
    const $emit = ctx.emit

    const priceManagementRef = ref(null)

    const pricingCustom = ref(false)
    const nextRedirection = ref(false)

    const product = inject('product')

    const currentKey = ref(0)
    const oldPrices = ref([])
    const isHangars = ['hangar', 'parking', 'outside_ramps'].includes(product.value.typology)

    const switchPrices = () => {
      if (pricingCustom.value) {
        product.value.mainVariant.prices = _cloneDeep(product.value._reference.mainVariant.prices)
        const initPrice = {
          id: null,
          fromQuantity: 1,
          toQuantity: null,
          fromQuantityUnit: 'hour',
          toQuantityUnit: 'hour',
          sellingPriceExcludingTaxes: 0,
          sellingPriceIncludingTaxes: 0,
          ecologicalTax: 0,
          packingQuantity: 1,
        }
        if (!isHangars) {
          if (!product.value.mainVariant.prices.length) {
            product.value.mainVariant.prices.push(initPrice)
          }
        } else {
          let periodicities = ['daily', 'weekly', 'monthly', 'annual']
          product.value.mainVariant.prices.forEach(price => {
            if (periodicities.includes(price.periodicity)) {
              periodicities = periodicities.filter(period => price.periodicity !== period)
            }
          })
          periodicities.forEach(period => {
            product.value.mainVariant.prices.push({ ...initPrice, periodicity: period })
          })
        }
      } else {
        product.value.mainVariant.prices = []
      }
      currentKey.value += 1
    }
    product.value.fetchPrice(ctx.root.$route.params.service_id, ctx.root.$route.query.vid).then(() => {
      pricingCustom.value = !!product.value.mainVariant.prices.length
      switchPrices()
    })

    // HANGAR TYPES
    const tabActive = ref('dailyPrice')
    const currentPeriod = ref('daily')
    const periods = [
      { tab: 'daily', label: 'Day' },
      { tab: 'weekly', label: 'Week' },
      { tab: 'monthly', label: 'Month' },
      { tab: 'annual', label: 'Year' },
    ]

    const onSubmit = async () => {
      const validForm = await priceManagementRef.value.validate()
      if (validForm) {
        await product.value.patchPrice()
        if (nextRedirection.value) {
          $emit('next-tab')
          nextRedirection.value = false
        }
      } else {
        toaster($i18n.t('alert.fill_required_fields'), 'danger', 'AlertTriangleIcon')
      }
    }

    return {
      product,
      isHangars,
      priceManagementRef,
      nextRedirection,
      currentKey,
      oldPrices,
      pricingCustom,
      onSubmit,
      tabActive,
      currentPeriod,
      periods,
      switchPrices,
    }
  },
})
</script>
